<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar
          class="mr-2"
          to="/usuarios"
        />

        <v-btn
          color="amber accent-4"
          class="white--text ml-0"
          @click="dialogAlterarSenha = true"
        >
          <v-icon
            class="mr-2"
          >
            mdi-lock-reset
          </v-icon>
          Alterar senha
        </v-btn>
      </v-col>
    </v-row>

    <usuarios-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :userToEdit="usuario"
      :grupos="grupos"
      @salvar="salvar"
    />

    <v-dialog
      :value="dialogAlterarSenha"
      max-width="500px"
      @click:outside="dialogAlterarSenha = false"
    >
      <v-card>
        <v-card-title class="headline amber accent-4 white--text">
          Alterar senha
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <v-row>
            <v-col>
              <validation-observer ref="formAlterarSenha">
                <validation-provider
                  v-slot="{ errors }"
                  vid="senha"
                  name="Nova senha"
                >
                  <v-text-field
                    v-model="novaSenha"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoAlterarSenha"
                    label="Nova senha"
                    type="password"
                    :error-messages="errors"
                    hint="Mínimo de 10 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caracter especial"
                    persistent-hint
                  />
                </validation-provider>
              </validation-observer>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <btn-cancelar
            text
            @click="dialogAlterarSenha = false"
          />
          <v-btn
            color="amber accent-4"
            :loading="carregandoAlterarSenha"
            text
            @click="alterarSenha"
          >
            <v-icon class="mr-2">
              mdi-lock-reset
            </v-icon>
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import usuarios from '@/api/usuarios'
  import autenticacao from "@/api/autenticacao";

  export default {
    components: {
      UsuariosCampos: () => import('./UsuariosCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        carregandoAlterarSenha: false,
        dialogAlterarSenha: false,
        validacaoFormulario: {},
        usuario: {},
        grupos: [],
        novaSenha: null,
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await usuarios.buscar(this.$route.params.id)
          const respostaGrupos = await usuarios.buscarGrupos(this.$route.params.id)

          this.grupos = respostaGrupos.data
          this.usuario = resposta.data
        } catch (e) {
          this.$router.push('/usuarios', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (usuario, grupos) {
        try {
          this.carregandoSalvar = true

          delete usuario.pessoa

          const formData = new FormData()
          formData.append('_method', 'put')
          Object.entries(usuario).forEach(([campo, valor]) => {
            formData.append(campo, valor)
          })
          await usuarios.atualizar(usuario.id, formData)
          this.carregandoSalvar = false
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          return
        } finally {
          this.carregandoSalvar = false
        }

        try {
          this.carregandoSalvar = true

          await usuarios.atualizarGrupos(usuario.id, {
            grupos: grupos.join(','),
          })

          this.$router.push('/usuarios', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          this.$router.push('/usuarios', () => this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Usuário salvo, mas erro ao salvar grupos, edite e verifique' }))
        } finally {
          this.carregandoSalvar = false
        }

        try {
          //- Atualiza a imagem do usuario
          this.carregandoSalvar = true

          const idUsuario = this.$store.state.usuario.usuario.id;
          if (idUsuario == usuario.id) {
            const resposta = await autenticacao.me();
            const imagemNova = resposta.data.imagem_usuario;
            this.$store.dispatch("atualizarImagem", imagemNova);
          }
        } catch (e){
          this.carregandoSalvar = false
        } finally {
          this.carregandoSalvar = false
        }
      },

      async alterarSenha () {
        try {
          this.carregandoAlterarSenha = true
          this.$refs.formAlterarSenha.setErrors({})

          await usuarios.alterarSenha(this.$route.params.id, {
            senha: this.novaSenha,
          })

          this.dialogAlterarSenha = false
          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_alterar_senha') })
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.formAlterarSenha.setErrors(this.$erroApi.validacao(e))
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoAlterarSenha = false
        }
      },

    },

  }
</script>
